import NextErrorComponent from 'next/error'
import { useState } from 'react'
import * as Sentry from '@sentry/nextjs'
import { useRecoilValue } from 'recoil'
import { currentSessionState, currentUpdateState } from 'atoms/Link'

import { useAccess } from 'providers/access'

import FullpageNotice from 'components/global/FullpageNotice'
import Button from 'components/global/Button'
import DocumentUpload from 'components/global/DocumentUpload'

const MyError = ({ statusCode, hasGetInitialPropsRun, err }) => {
  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(err)
    // Flushing is not required in this case as it only happens on the client
  }

  const [showDocumentUpload, setShowDocumentUpload] = useState(false)

  // Access session
  const currentSession = useRecoilValue(currentSessionState)
  const currentUpdate = useRecoilValue(currentUpdateState)

  // Access app
  const access = useAccess()

  return (
    <>
      {showDocumentUpload ? (
        <DocumentUpload setShowDocumentUploadScreen={setShowDocumentUpload} />
      ) : (
        <FullpageNotice
          type="error"
          title="Connection unsuccessful"
          message="We could not complete the linking process."
          actions={
            <div className="flex gap-3">
              {currentSession?.integrationKey && (
                <Button color="white" onClick={() => access.reset(true)}>
                  Start over
                </Button>
              )}
              {currentUpdate?.actions.show_document_upload &&
                currentSession?.linkId && (
                  <Button color="white" onClick={() => {}}>
                    Upload a policy document
                  </Button>
                )}
            </div>
          }
        />
      )}
    </>
  )
}

MyError.getInitialProps = async (context) => {
  const errorInitialProps = await NextErrorComponent.getInitialProps(context)

  const { res, err, asPath } = context

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  errorInitialProps.hasGetInitialPropsRun = true

  // Returning early because we don't want to log 404 errors to Sentry.
  if (res?.statusCode === 404) {
    return errorInitialProps
  }

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (err) {
    Sentry.captureException(err)

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await Sentry.flush(2000)

    return errorInitialProps
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  Sentry.captureException(
    new Error(`_error.js getInitialProps missing data at path: ${asPath}`)
  )
  await Sentry.flush(2000)

  return errorInitialProps
}

export default MyError
